import {makeVar} from '@apollo/client'
import {decodeJwt, JWTPayload} from 'jose'

const TOKEN_KEY = 'token'

export const getJwt = (): string | null => {
  return localStorage.getItem(TOKEN_KEY)
}

// Apollo reactive variable
export const isSignedInVar = makeVar<boolean>(!!getJwt())

export const setJwt = (token: string): void => {
  localStorage.setItem(TOKEN_KEY, token)

  // update the reactive variable, so any active queries also get updated
  isSignedInVar(true)
}

export const clearJwt = (): void => {
  localStorage.removeItem(TOKEN_KEY)

  // update the reactive variable, so any active queries also get updated
  isSignedInVar(false)
}

// TODO: create a StorageEvent listener to watch for localStorage changes from other tabs

export const getPayload = (): JWTPayload | undefined => {
  let payload: JWTPayload | undefined

  const token = getJwt()

  if (token) {
    payload = decodeJwt(token)
  }

  return payload
}

export const getCurrentUserId = (): string | undefined => {
  return getPayload()?.sub
}

export const getRoles = (): string[] => {
  const roles = getPayload()?.roles as string[] ?? []

  return roles
}
